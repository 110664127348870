.color-vivacom {
  background-color: #505050;
  color: #ffffff;
}

.color-a1 {
  background-color: #ff5050;
  color: #ffffff;
}

.color-telenor {
  background-color: #5050ff;
  color: #ffffff;
}

.color-rest {
  background-color: #5050ff;
  color: #ffffff;
}

div.ag-theme-alpine div.ag-row {
  font-size: 12px !important;
}

.ag-header-cell-text {
  font-size: 12px !important;
}

div.ag-cell {
  line-height: 25px !important;
}

span.ag-icon-tree-open,
span.ag-icon-tree-closed {
  margin-top: -15px !important;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.MuiListItemText-root > .MuiTypography-body1 {
  font-size: 0.8rem;
}
